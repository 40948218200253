<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>
            View observation requested for
            {{ observation.date | moment("dddd, MMMM Do YYYY") }}<br />
            Learner:
            {{ observation.learner.first_name }} {{ observation.learner.last_name }}<br />
            Observer:
            {{ observation.observer.first_name }} {{ observation.observer.last_name }}
          </h1>
          <div class="grid-x pad-top--small">
            <div class="cell small-1">
              <p>
                <span
                  v-if="observation.accepted === false"
                  class="indicator new">
                  &nbsp;
                </span>
                <span
                  v-if="observation.accepted === true"
                  class="indicator passed">
                  &nbsp;
                </span>
              </p>
            </div>
            <div v-if="observation.accepted === false" class="cell small-9 medium-3">
              <div
                v-if="userLevel === 1 || userLevel === '1' || userLevel === 2 || userLevel === '2'">
                <button v-on:click="acceptObservation" class="button">Accept observation</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-bind:class="formClass"
      class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <form class="report-form">
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-6">
              <label>Click the button below to select a report to upload</label>
              <div class="report-button"></div>
              <div><p>{{ filename }}</p></div>
              <button class="button">Save</button>
              <div class="UppyProgressBar"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-if="observation.report_url !== false" class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <p>
          <a v-bind:href="observation.report_url" class="button" target="_blank" download>
            Download report
          </a>
        </p>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h3>Address:</h3>
          <div v-html="observation.address"></div>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h3>Description:</h3>
          <div v-html="observation.description"></div>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h3>Comments</h3>
          <ul class="comments">
            <li v-for="(comment, index) in observation.comments" v-bind:key="index">
              {{ comment.user.first_name }} {{ comment.user.last_name }} commented on
              {{ comment.created | moment("dddd, MMMM Do YYYY") }}
              <div v-html="comment.comment"></div>
              <div v-if="comment.file_location">
                <a
                  target="_blank"
                  class="button"
                  v-bind:href="comment.file_location">
                  Download file
                </a>
              </div>
              <button
                v-if="comment.comment_read === false"
                @click="markCommentRead(comment.id)"
                class="button">
                Mark as Read
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <app-comment-form v-bind:observation="observation" v-on:commentSaved="addComment">
    </app-comment-form>
    <div
      v-if="message.length > 0"
      class="message-wrap">
      <span class="close-message" v-on:click="reload()">X</span>
      <p class="message-text">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import CommentForm from '@/components/Comment.vue';
import axios from '../axios';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

const Uppy = require('@uppy/core');
const UppyForm = require('@uppy/form');
const Transloadit = require('@uppy/transloadit');
const ProgressBar = require('@uppy/progress-bar');
const FileInput = require('@uppy/file-input');

export default {
  name: 'ViewObservation',
  components: {
    appCommentForm: CommentForm,
  },
  data() {
    return {
      observation: {
        learner: {
          first_name: '',
          last_name: '',
        },
        observer: {
          first_name: '',
          last_name: '',
        },
        report_url: false,
      },
      comment: {},
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: '1' }, { header: '2' }],
        ['link'],
      ],
      filename: '',
      message: '',
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    userLevel() {
      return this.$store.getters.userLevel;
    },
    formClass() {
      return {
        hidden: this.observation.report_url !== false,
        hide: this.userLevel === '3',
        noShow: this.userLevel === 3,
      };
    },
  },
  methods: {
    markCommentRead(commentId) {
      axios.get(`/comments/markRead/${commentId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`);
      this.message = 'The comment has been marked read';
    },
    acceptObservation() {
      axios.post(`/observationRequests/accept.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, this.observation)
        .then((response) => {
          this.observation = response.data.observationRequest;
        });
    },
    addComment(value) {
      this.observation.comments.push(value);
      this.message = 'Your comment has been saved.';
    },
    getObservation() {
      const observationId = this.$route.params.id;
      axios.get(`/observationRequests/getTheRequest/${observationId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.observation = response.data.observationRequest;
        });
    },
    uploadReport(result) {
      const observationId = this.$route.params.id;
      const files = result;
      axios.post(`/observationRequests/addReport/${observationId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, files)
        .then((response) => {
          this.observation = response.data.observationRequest;
        });
    },
  },
  mounted() {
    this.getObservation();
    Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: (1000000 * 1000),
        maxNumberOfFiles: 3,
        minNumberOfFiles: 0,
      },
    })
      .use(UppyForm, {
        target: '.report-form',
        multipleResults: true,
        triggerUploadOnSubmit: true,
        submitOnSuccess: false,
      })
      .use(FileInput, {
        id: 'Report',
        target: '.report-button',
        pretty: true,
        inputName: 'report',
      })
      .on('file-added', (res) => {
        this.filename = res.name;
      })
      .use(ProgressBar, {
        target: '.UppyProgressBar',
        hideAfterFinish: false,
      })
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
          auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
          template_id: 'ad27d2c2cad8454fba2791c04c58c00e',
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
      })
      .on('complete', (result) => {
        this.uploadReport(result);
      });
  },
};
</script>
